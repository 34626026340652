// globat charset
@charset "UTF-8";

@import 'styles/_fonts.scss';
@import 'styles/_variables.scss';
@import 'styles/_mixins.scss';

.App {
  height: 100%;
  width: 100%;
  display: inline-block;
  font-family: "SFProDisplay-Regular";
  background-color: $neo-white;
}

p, h1, h2, h3, h4, h5, h6 {
  margin: 0;
}


@import 'styles/_variables.scss';
@import 'styles/_mixins.scss';

.box {
    height: 0;
    width: 0;
    position: absolute;

    .language-dropdown {
        position: relative;
        font-family: "SFProDisplay-Regular";
        font-size: 1.05rem;
        top: 2rem;
        right: 8.75rem;
        border-radius: 1.5rem 0 1.5rem 1.5rem;
        background: $neo-white;
        box-shadow: 3px 2px 6px rgba(0, 0, 0, 0.3);
        width: 8.7rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        z-index: 99999;
        @include mobile {
            padding: 0.5rem 1rem;
            font-size: 1.8rem;
            width: 10rem;
            top: 2.625rem;
            right: 12.5rem;
        }

        .line {
            height: 0.1rem;
            width: 2rem;
            background: $neo-lightblue;
        }

        li {
            list-style: none;
            width: 100%;
            padding: 0.8rem;
            button {
                background: transparent;
                border: 0;
                width: 5rem;
                border-radius: 0.5rem;
                font-size: 1rem;
            }
            &:hover{
                font-family: "SFProDisplay-Semibold";
                scale: 102%;
                transform: scale 0.5s ease;
                color: $neo-darkpurple;
            }
        }
    }
}
@import 'styles/_variables.scss';
@import 'styles/_mixins.scss';

.footer-neo {
    background-color: $neo-footer;
    height: 14rem;
    box-sizing: border-box;
    width: 100%;
    padding: 2rem 4rem;
    display: grid;
    align-content: flex-end;
    grid-template-columns: 2fr 1fr 1fr;
    grid-template-areas: "left center right";
    z-index: 99999;
    color: $neo-white;
    font-family: "SFProDisplay-Light";
    font-size: 1.2rem;

    @include mobile {
        height: 16rem;
        font-size: 1.5rem;
        padding: 2rem 2rem;
        gap: 4px;
    }

    .mobile-footer {
        @include tablet-portrait {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            height: 100%;
            justify-content: flex-end;
            text-align: left;
        }

        @include mobile {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            height: 100%;
            width: 85%;
            justify-content: flex-end;
            
        }

        span {
            @include tablet-portrait {
                margin-top: 1rem;
            }

            @include mobile {
                margin-top: 1rem;
            }
        }
    }

    &_name {
        grid-area: left;
        color: &neo-white;
        cursor: pointer;
        text-decoration: none;
        display: flex;
        justify-content: flex-start;
        align-self: flex-start;
        align-items: flex-start;
        font-family: 'NeueHaasDisplay-Bold';
        font-size: 1.5rem;
        margin-bottom: 2rem;

        &:active, &:visited, &:link {
            color: $neo-white;
        }

        @include mobile{
            margin-bottom: 1rem;
        }

        @include tablet-landscape {
            align-self: flex-end;
            // margin-bottom: 0;
        }

    }

    &_terms {
        grid-area: center;
        text-align: right;
        align-items: flex-end;
        display: flex;
        justify-content: flex-end;
        margin-right: 2rem;
        width: max-content;
        gap: 2rem;
        @include mobile {
            text-align: left;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            gap: 1rem;
        }

        a {
            font-size: 1rem;
            color: $neo-white;
            text-decoration: none;
            @include mobile{
                font-size: 1.5rem; 
            }
        }

        a:hover {
            color: $neo-violet;
        }
    }

    &_socials {
        align-self: flex-end;
        display: flex;
        flex-direction: column;
        grid-area: right;
        text-align: right;
        fill: $neo-white;
        width: max-content;

        &--social{
            font-size: 1.2rem !important;
        }

        &--follow {
            font-size: 1.2rem;

            @include mobile {
                font-size: 1.4rem;
                margin-bottom: 0;
            }

            @include tablet-portrait {
                margin-bottom: 0;
            }

            @include tablet-landscape {
                margin-bottom: 1rem;
            }
        }
    }
}
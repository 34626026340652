@mixin font-face($name, $fileName) {
    $fileName: '../assets/fonts/' + $fileName;

    @font-face {
        font-family: quote($name);
        src: url(quote($fileName + ".woff2")) format("woff2"),
        url(quote($fileName + ".woff")) format("woff");
        font-weight: normal;
        font-style: normal;
    }
}

@include font-face('SFProDisplay-Light', 'SFProDisplay-Light');
@include font-face('SFProDisplay-LightItalic', 'SFProDisplay-LightItalic');
@include font-face('SFProDisplay-Regular', 'SFProDisplay-Regular');
@include font-face('SFProDisplay-RegularItalic', 'SFProDisplay-RegularItalic');
@include font-face('SFProDisplay-Medium', 'SFProDisplay-Medium');
@include font-face('SFProDisplay-MediumItalic', 'SFProDisplay-MediumItalic');
@include font-face('SFProDisplay-Semibold', 'SFProDisplay-Semibold');
@include font-face('SFProDisplay-SemiboldItalic', 'SFProDisplay-SemiboldItalic');
@include font-face('SFProDisplay-Bold', 'SFProDisplay-Bold');
@include font-face('SFProDisplay-BoldItalic', 'SFProDisplay-BoldItalic');
@include font-face('SFProDisplay-Black', 'SFProDisplay-Black');
@include font-face('SFProDisplay-BlackItalic', 'SFProDisplay-BlackItalic');
@include font-face('NeueHaasDisplay-Medium', 'NeueHaasDisplay-Medium');
@include font-face('NeueHaasDisplay-Bold', 'NeueHaasDisplay-Bold');
@include font-face('NeueHaasDisplay-Roman', 'NeueHaasDisplay-Roman');
@include font-face('NeueHaasDisplay-Light', 'NeueHaasDisplay-Light');
@import 'styles/_variables.scss';
@import 'styles/_mixins.scss';

.boxSearch {
    height: 0;
    width: 0;
    position: absolute;

    .search-dropdown{
        position: relative;
        font-family: "SFProDisplay-Regular";
        font-size: 1.05rem;
        top: 2rem;
        right: 12.5rem;
        border-radius: 1.5rem 0 1.5rem 1.5rem;
        background: $neo-white;
        box-shadow: 3px 2px 6px rgba(0, 0, 0, 0.3);
        width: 14rem;
        height: 4rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        z-index: 99999;
        @include mobile {
            padding: 0.5rem 1rem;
            font-size: 1.8rem;
            height: 5rem;
            width: 20rem;
            top: 2.625rem;
            right: 20.5rem;
        }

        input{
            border: none;
            border-bottom: 2px solid $neo-input; 
            height: 50%;
            width: 70%;
            font-family: "SFProDisplay-Light";
            font-size: 1.3rem;
            color: rgb(65, 65, 65);
            @include mobile{
                font-size: 2rem;
            }

            &:focus-visible{
                outline: none;
            }
        }       
    }
    .result{
        font-family: "SFProDisplay-Light";
        font-size: 1.1rem;
        z-index: 99999;
        position: absolute;
        background-color: white;
        min-height: 0rem;
        max-height: 10rem;
        width: 11.5rem;
        top: 99%;
        overflow-y: scroll;
        overflow-x: hidden;
        border-radius: 0 0 20px 20px;
        text-align: start;
        @include mobile{
            width: 15rem;
            font-size: 1.5rem;
        }
        ul{
            display: flex;
            flex-direction: column;
            gap: 10px;
        }
        li{
            list-style: none;
            padding: 1rem 1rem 0 1rem;
            hr{
                margin-top: 1rem;
                color: red;
            }
        }
        p{
            text-align: center;
            padding: .5rem ;
        }
       
    }
}

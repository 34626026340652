@import 'styles/_variables.scss';
@import 'styles/_mixins.scss';

.infoCareer {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    border-radius: 1.5rem;
    background: $neo-white;
    box-shadow: $neo-shadow;
    margin-bottom: 2rem;
    padding: 2rem 3rem;
    height: auto;
    transition: all 2s ease-in-out;


    @include mobile {
        padding: 4rem 2rem;
    }

    &_title {
        font-family: "SFProDisplay-Semibold";
        font-size: 1.5rem;
        margin-bottom: 2rem;

        @include mobile {
            font-size: 2.5rem;
        }
    }

    &_description {
        font-family: "SFProDisplay-Light";
        font-size: 1.2rem;

        @include mobile {
            font-size: 1.8rem;
        }
    }

    &_subtitle {
        font-family: "SFProDisplay-Medium";
        font-size: 1.2rem;
        margin-block: 1.2rem;

        @include mobile {
            font-size: 1.8rem;
            
        }
    }
    

    &_bullet {
        font-family: "SFProDisplay-Light";
        font-size: 1.2rem;
        margin-left: 1.5rem;

        @include mobile {
            font-size: 1.8rem;
            margin-left: 2rem;
        }
    }

    .submit-button {
        width: 12rem;
        height: 3rem;
        text-align: center;
        align-self: center;
        margin-top: 2rem;

        @include mobile {
            width: 100%;
        }

        button {
            font-size: 1.2rem;
            font-family: "SFProDisplay-Bold";
            border-radius: 4rem;
            border: 3px solid $neo-primary;
            padding: 1rem 2rem;
            background-color: transparent;
            color: $neo-primary;

            @include mobile {
                width: 100%;
                border-radius: 1rem;
                font-size: 1.8rem;
                border: 1px solid $neo-primary;
            }

            &:hover {
                cursor: pointer;
                filter: brightness(1.1);
                box-shadow: 0px 3px 10px  $neo-primary;
            }
        }
    }
}
@import 'styles/_variables.scss';
@import 'styles/_mixins.scss';

.adminHome {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-inline: 4rem;
    background-color: $neo-white;
    min-height: 100vh;
    h2 {
        font-family: 'NeueHaasDisplay-Medium';
        font-size: 1.8rem;

    }

    .logout {
        display: flex;
        align-self: self-end;
        margin: 0;
        margin-right: 3rem;

    }

    @include tablet-portrait {
        padding-inline: 3rem;
    }

    @include mobile {
        padding-inline: 2rem;

    }


}

.btn-adminHome {
    margin: 1rem;
    padding: .6rem;
    background-color: #F7838D;
    border: none;
    box-sizing: border-box;
    border-radius: 2rem;

    a {
        cursor: pointer;
        font-family: 'NeueHaasDisplay-Medium';
        font-size: 1.2rem;
        text-decoration: none;
        color: $neo-white;
    }

    .logout {
        margin-left: 1rem;
    }
}
@import 'styles/_variables.scss';
@import 'styles/_mixins.scss';

.cardCareer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    border: none;
    width: 100%;
    height: 14rem;
    border-radius: 1.5rem;
    background: $neo-white;
    box-shadow: $neo-shadow;
    margin-bottom: 2rem;
    padding: 2rem;
    cursor: pointer;

    @include mobile {
        height: 18rem;
    }

    &_title {
        font-family: "SFProDisplay-Semibold";
        font-size: 1.5rem;
        margin-bottom: 1rem;

        @include mobile {
            font-size: 2.5rem;
        }
    }

    &_location {
        display: flex;
        flex-direction: row;
        align-items: center;
        opacity: 0.6;
        font-family: "SFProDisplay-Light";
        font-size: 1.2rem;
        margin-bottom: 1rem;
        color: $neo-green;

        @include mobile {
            font-size: 1.8rem;
            opacity: 0.8;
        }

        &--icon {
            height: 1.2rem;
            fill: $neo-green;
            margin-right: 0.5rem;

            @include mobile {
                height: 1.8rem;
            }
        }
    }

    &_time {
        display: flex;
        flex-direction: row;
        align-items: center;
        opacity: 0.6;
        font-family: "SFProDisplay-Light";
        font-size: 1.2rem;
        margin-bottom: 1rem;
        color: $neo-green;

        @include mobile {
            font-size: 1.8rem;
            opacity: 0.8;
        }

        &--icon {
            height: 1.2rem;
            fill: $neo-green;
            margin-right: 0.5rem;

            @include mobile {
                height: 1.8rem;
            }
        }
    }

    &_postedOn {
        font-family: "SFProDisplay-Light";
        font-size: 1rem;
        color: $neo-gray;

        @include mobile {
            font-size: 1.5rem;
        }
    }
}

.Mui-selected {
    border: 3px solid $neo-green;

    @include mobile {
        border: 1px solid $neo-green;
    }
}

.selected {
    border: 3px solid $neo-green;

    @include mobile {
        border: 1px solid $neo-green;
    }
}
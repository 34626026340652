@import 'styles/_variables.scss';
@import 'styles/_mixins.scss';

.careersPage {
    padding: 0rem 5rem 2rem;
    color: $neo-primary;

    @include mobile {
        padding: 8rem 2rem 2rem;

    }

    &_load {
        margin-top: 10%;
        margin-bottom: 25%;

        @include mobile {
            margin-bottom: 80%;
        }
    }

    &_jobs {
        display: flex;

        @include mobile {
            display: flex;
            flex-direction: column;
        }

        &--card {
            padding-right: 2rem;
            width: 33%;
            height: 80vh;
            overflow-y: scroll;

            @include mobile {
                width: 100%;
                padding-right: 0;

            }
        }

        &--description {
            padding-left: 2rem;
            width: 66%;

            @include mobile {
                width: 100%;
                padding-left: 0;
            }
        }
    }

    &_noJobs {
        padding: 7rem 12rem 7rem;
        width: 80%;

        @include mobile {
            padding: 3.5rem;
        }

        &--title {
            font-size: 1.5rem;
            font-family: 'SFProDisplay-Medium';
            margin-bottom: 1.5rem;

            @include mobile {
                font-size: 2.5rem;
            }
        }

        &--text {
            font-size: 1.5rem;
            font-family: 'SFProDisplay-Light';

            @include mobile {
                font-size: 2.2rem;
            }

            mark {
                background-color: transparent;
                font-family: 'SFProDisplay-Semibold';
                color: $neo-primary;
            }

        }

        &--link {
            list-style: none;
            font-size: 1.5rem;
            font-family: 'SFProDisplay-Light';
            margin-top: 2rem;
            

            @include mobile {
                font-size: 2.2rem;
            }

            a {
                font-family: 'SFProDisplay-Medium';
                text-decoration: none;
                color: $neo-green;
            }
        }


    }
}
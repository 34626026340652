@import 'styles/_variables.scss';
@import 'styles/_mixins.scss';
@import 'styles/_fonts.scss';

.modalComponent {
    position: fixed;
    z-index: 1300;
    inset: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    &-box {
        width: max-content;
        height: max-content;
        display: flex;
        flex-direction: column;
        border-radius: 1.5rem;
        border: 3px solid $neo-green;
        background: $neo-white;
        box-shadow: $neo-shadow;
        margin-bottom: 2rem;
        padding: 2rem;
        gap: 1rem;

        &__title {
            font-size: 1.7rem;
            font-family: 'SFProDisplay-Regular';
        }

        &__text {
            font-family: 'SFProDisplay-Regular';
            font-size: 1.2rem;
        }

        &_btn {
            display: flex;
            width: 100%;
            justify-content: space-around;

            &-style {
                background-color: $neo-jobs-background;
                font-family: 'SFProDisplay-Regular';
                color: $neo-white;
                border-style: none;
                border-radius: 1.5rem;
                width: 5.2rem;
                height: 2.2rem;
                opacity: 100%;
                text-align: center;
                font-size: 1rem;
                cursor: pointer;

            }
        }
    }
}
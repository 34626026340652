@import 'styles/_variables.scss';
@import 'styles/_mixins.scss';

.rols {
    display: grid;
    grid-template-rows: auto 1fr 0.5fr 0.5fr;
    grid-template-areas: "title" "first" "second" "third";
    max-width: 100%;
    margin: 0rem 5rem 2rem;
    background-image: url("../../assets/images/background-legal.png");
    background-repeat: repeat;
    background-size: 100%;
    background-position-x: center;
    background-position-y: center;

    @include mobile {
        display: flex;
        flex-direction: column;
        margin: 8rem 3rem 2rem;
    }

    &_title {
        grid-area: title;
        color: $neo-primary;
        font-size: 2rem;
        font-family: 'NeueHaasDisplay-Medium';
        margin-bottom: 2rem;       

        mark {
            background-color: transparent;
            font-family: 'NeueHaasDisplay-Bold';
            color: $neo-purple;
        }
    }

    .first {
        grid-area: first;
    }

    .second {
        grid-area: second;
    }

    .third {
        grid-area: third;
    }

    &_row {
        display: flex;
        flex-direction: row;
        width: 100%;
        gap: 1.2rem;
        margin-bottom: 1rem;

        .container {
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 1.2rem;
        }

        .col_first {
            width: 45%;

            @include mobile {
                width: 60%;
            }
        }

        .col_second {
            width: 25%;

            @include mobile {
                width: 40%;
            }
        }

        .col_third {
            width: 30%;
            @include mobile {
                width: 40%;
                display: flex;
                flex-direction: column;
                justify-content: center;
            }
        }

        .col_fourth {
            @include mobile {
                width: 33%;
            }
        }

        &--card {
            color: $neo-white;
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            gap: 1.2rem;
            padding: 2rem;
            border-radius: 1rem;

            h2 {
                font-size: 2rem;
                font-family: 'NeueHaasDisplay-Bold';
                line-height: 1.2rem;

                mark {
                    color: $neo-white;
                    font-size: 1.2rem;
                    font-family: 'SFProDisplay-Light';
                    background-color: transparent;
                }

                @include mobile {
                    font-size: 2.5rem;
                    line-height: 1.5rem;

                    mark {
                        font-size: 1.5rem;
                    }
                }
            }

            p {
                font-size: 1.2rem;
                font-family: 'SFProDisplay-Light';

                @include mobile {
                    font-size: 1.5rem;
                }
            }
        }

        .transparent {
            background-color: transparent;

            h2 {
                font-size: 2.5rem;
                font-family: 'NeueHaasDisplay-Bold';
                line-height: 1.8rem;

                mark {
                    font-size: 1.8rem;
                    font-family: 'SFProDisplay-Light';
                    background-color: transparent;
                }
            }
        }

        .backend {
            height: auto;
            padding: 4rem 2rem;
            background: $neo-lightpurple;
            @include mobile {
                padding: 2rem;
            }
        }

        .essencial {
            height: 40%;
            color: $neo-lightpurple;
            align-items: flex-start;
            justify-content: center;

            mark {
                color: $neo-lightpurple;
            }
        }

        .logic {
            height: 40%;
            background: $neo-purple-to-lightpurple;
            justify-content: flex-end;
            align-items: flex-start;
        }

        .dev-image {
            height: 60%;
        }

        .community {
            color: $neo-purple;
            height: 40%;
            justify-content: center;
            align-items: flex-start;

            mark {
                color: $neo-purple;
            }
        }

        .frontend {
            height: auto;
            padding: 4rem 2rem;
            background: $neo-purple-to-red;
            justify-content: center;
            align-items: flex-start;

            @include mobile {
                padding: 2rem;
                width: 60%;
            }
        }

        .product {
            width: 60%;
            background: $neo-lightred;

            @include mobile {
                width: 100%;
            }
        }

        .minds {
            width: 40%;
            height: auto;
            color: $neo-red;
            align-items: flex-end;
            justify-content: center;

            mark {
                color: $neo-red;
            }

            @include mobile {
                text-align: right;
                width: 100%;
            }
        }

        .commited {
            width: 30%;
            height: auto;
            color: $neo-dark-pink;
            align-items: flex-end;
            justify-content: center;

            mark {
                color: $neo-dark-pink;
            }

            @include mobile {
                width: 100%;
            }
        }

        .devops {
            width: 40%;
            height: auto;
            background: $neo-dark-pink-to-pink;

            @include mobile {
                width: 66%;
                background: $neo-pink-to-dark-pink;
            }
        }

        .design-image {
            width: 30%;
            height: 18rem;

            @include mobile {
                width: 100%;
            }
        }

        .image {
            padding: 0;
            border-radius: 1rem;
            overflow: hidden;

            img {
                width: 100%;
                object-fit: cover;
                border-radius: 1rem;
            }
        }
    }
}
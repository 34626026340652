.socials {
    display: flex;
    flex-direction: row;
    gap: 0.7rem;
    margin-top: 0.5rem;
    a {
        height: inherit;
        width: fit-content;
    }
    &_icons {

        display: flex;
        flex-direction: row;
        height: inherit;
        fill: inherit;
        width: auto;
        &:hover {
            scale: 1.1;
        }
    }
}
@import 'styles/_variables.scss';
@import 'styles/_mixins.scss';

.navigation {
  font-family: "SFProDisplay-Medium";

  @include mobile {
    flex-direction: column;
  }

  &_sections {
    display: flex;
    list-style-type: none;
    align-items: center;
    justify-content: space-around;

    @include mobile {
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      padding: 4rem;
      margin: 1rem;
      width: 54%;
      background-color: $neo-white;
      position: absolute;
      top: 8rem;
      right: 36%;
      z-index: 6;
      transform: translateX(58%);
      transition: transform 0.5s ease;
      font-size: 1.8rem;
      overflow: hidden;
      border-radius: 2rem 0rem 1rem 2rem;
      gap: 3rem;

      &.is-active {
        transform: translateX(0);
      }
    }

    li {
      cursor: pointer;


      &:hover {
        scale: 105%;
        transition: scale 0.5s ease;
      }

      &.icons:hover{
        scale: none;
        transition: none;
      }


      a {
        color: $neo-primary;
        text-decoration: none;

        @include mobile {
          display: flex;
          padding: 0;
        }

      }
    }
  }

  .btnLogin {

    background-color: $neo-jobs-background;
    font-family: 'SFProDisplay-Regular';
    color: $neo-white;
    border-style: none;
    border-radius: 1.5rem;
    width: 5.2rem;
    height: 2.2rem;
    opacity: 100%;
    text-align: center;
    font-size: 1rem;
    cursor: pointer;
    padding: 0.5rem;

    @include mobile {
      width: 100%;
      color: $neo-primary;
      background-color: transparent;
      font-family: "SFProDisplay-Medium";
      font-size: 1.8rem;
    }

  }
}


body {
  margin: 0;
  padding: 0;
}

.hamburger {
  display: none;

  @include mobile {
    display: block;
    position: relative;
    background-color: transparent;
    left: 91%;
    top: 3.5rem;
    // -webkit-transform:translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    // -webkit-transition:-webkit-transform 0.25s cubic-bezier(0.05, 1.04, 0.72, 0.98);
    transition: transform 0.25s cubic-bezier(0.05, 1.04, 0.72, 0.98);
    z-index: 7;
    cursor: pointer;
    // -webkit-user-select:none;
    // -moz-user-select:none;
    // -ms-user-select:none;
    user-select: none
  }

}

.hamburger.is-active {
  background-color: none;
}

._layer {
  background: $neo-primary;
  margin-bottom: 0.25rem;
  border-radius: 2rem;
  width: 3rem;
  height: 0.5rem;
  opacity: 1;
  // -webkit-transform:translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  // -webkit-transition:all 0.25s cubic-bezier(0.05, 1.04, 0.72, 0.98);
  transition: all 0.25s cubic-bezier(0.05, 1.04, 0.72, 0.98);
}

.hamburger.is-active .-top {
  background: $neo-white;
  // -webkit-transform:translateY(200%) rotate(52deg) !important;
  // -ms-transform:translateY(200%) rotate(52deg) !important;
  transform: translateY(200%) rotate(52deg) !important;
}

.hamburger.is-active .-mid {
  opacity: 0;
}

.hamburger.is-active .-bottom {
  background: $neo-white;
  // -webkit-transform:translateY(-200%) rotate(132deg) !important;
  // -ms-transform:translateY(-200%) rotate(132deg) !important;
  transform: translateY(-130%) rotate(130deg) !important;
}

.menuppal.is_active {
  transform: translate3d(0px, 0px, 0px);
}

.menuppal {
  background-color: $neo-white;
  bottom: 0;
  height: 100%;
  left: 0;
  overflow-y: scroll;
  position: fixed;
  top: 0;
  transform: translate3d(0px, -100%, 0px);
  width: 100%;
  z-index: 1001;
}

.menuppal ul {
  margin: 0;
  padding: 0;
}

.menuppal ul li {
  list-style: none;
  text-align: center;
  font-family: 'SFProDisplay-Regular';
  color: $neo-primary;
  font-size: 1.5rem;
  line-height: 3em;
  height: 3em;
  text-transform: none;
  font-weight: bold;
}

.menuppal ul li a {
  text-decoration: none;
}

.menuppal ul li a:hover {
  text-decoration: none;
}

.menu-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  /* Fondo oscuro con transparencia */
  z-index: 5;
  /* Asegura que esté por encima del contenido */
}
// Default colors
$neo-primary: #0C2B3F;
$neo-secondary: rgba(19, 54, 78, 0.6);
$neo-white: #FFFFFF;
$neo-violet: #12006C;
$neo-purple: #B186F1;
$neo-lightpurple: #D3BCF6;
$neo-red: #F7838D;
$neo-lightred: #F19ABA;
$neo-dark-pink: #EC9ECA;
$neo-pink: #FEDAEE;
$neo-newsletter: #F19ABA;
$neo-green: #01818C;
$neo-lightblue: #AECDDD;
$neo-blue: #094374;
$neo-footer: #7899B8;
$neo-jobs-background: #01818C;
$neo-jobs: #89DDB3;
$neo-lightblue-background: rgba(180, 204, 224, 0.35);
$neo-contact-background: #B4CCE0;
$neo-input: rgba(177, 134, 241,0.5);
$neo-error: rgba(248, 121, 121, 0.955);
$neo-darkpurple: #764AB8;
$neo-lightgreen:#80C5A5;
$neo-bluegrey:#3C5465;
$neo-blueinput: #042D50;
$neo-sky-blue:#0EBFCE;
$neo-gray: #AAA8A8;


// Gradients
$neo-purple-to-lightpurple: linear-gradient(270deg, #B186F1, #D3BCF6 100%);
$neo-purple-to-red: linear-gradient(180deg, #B186F1, #F7838D 100%);
$neo-dark-pink-to-pink: linear-gradient(270deg, #EC9ECA, #FEDAEE 100%);
$neo-pink-to-dark-pink: linear-gradient(270deg, #FEDAEE, #EC9ECA 100%);


// Default shadows
$neo-shadow: 0px 3px 10px #00000017;
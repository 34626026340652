@import 'styles/_variables.scss';
@import 'styles/_mixins.scss';


.cardProposal {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    border: none;
    width: 40%;
    height: 14rem;
    border-radius: 1.5rem;
    background: $neo-white;
    box-shadow: $neo-shadow;
    margin-bottom: 2rem;
    padding: 2rem;
    @include mobile {
        height: 18rem;
        width:100%;
    }

    &-btn{
        margin-left:95% ;
        width: 4%;
        background: transparent;
        border: none;
        
    }
    &-btn:hover{
        width: 4.2%;
        cursor: pointer;
        
    }
    &_load {
        width: 100vh;
        margin: 0 auto;

        @include mobile {
            margin-bottom: 80%;
        }
    }
}

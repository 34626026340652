@import 'styles/_variables.scss';
@import 'styles/_mixins.scss';

.uploadProposal{
    background-color: #13364E;
    padding: 3rem 8rem 5rem;
    display: grid;
    border-radius: 2rem;
    margin: 0 5rem 2rem;
    color: #ffff;
    font-family: 'SFProDisplay-Light';
    font-size: 1.2rem;
    @include mobile{
        padding: 2rem 2rem 5rem;
        margin: 0px 2rem 2rem;
    }
    .conditions{
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        padding: .4rem 1rem 0rem;
    }

    h2{
        font-family: 'NeueHaasDisplay-Light';
        font-size: 1.9rem;
        margin-bottom: 2rem;
        @include mobile{
            font-size: 2.3rem;
        }
        mark {
            background-color: transparent;
            font-family: 'NeueHaasDisplay-Medium';
            color: $neo-sky-blue;
        }
    }
    
    .inputTitle{
        border: solid 2px $neo-white;
        border-radius: 2rem;
        height: 3rem;
        width: 100%;
        font-family: "SFProDisplay-Light";
        font-size: 1.4rem;
        background-color: transparent;
        text-align: left;
        color: $neo-white;
        padding-left: 1rem;
        margin-top: 1rem;
        @include mobile{
            border-radius: 1rem;
            height: 3rem;
            font-size: 1.8rem;
            
        }
        &:focus-visible {
            outline: none;
            border: $neo-input solid 3px;
        }
        &::-ms-expand {
            display: none;
        }
        &:hover {
            cursor: pointer;
        }
    }


    .select {
        background-color: $neo-white;
        text-align: left;
        border: none;
        border-radius: 2rem;
        height: 3rem;
        width: 100%;
        font-family: "SFProDisplay-Light";
        font-size: 1.4rem;
        color: $neo-primary;
        margin-top: 1rem;
        padding-left: 1rem;
        appearance: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        background-image: url('../../../assets/icons/icon-down-arrow.svg');
        background-repeat: no-repeat;
        background-position: 93%;
        background-size: 1rem;
        @include mobile{
            border-radius: 1rem;
            height: 3rem;
            font-size: 1.8rem;
    
        }
    
        &:focus-visible {
            outline: none;
            border: $neo-input solid 3px;
        }
        &::-ms-expand {
            display: none;
        }
        &:hover {
            cursor: pointer;
        }
    }
    .valid {
        border: 3px solid $neo-white;
    }

    &_form {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 2rem;
    
        @include mobile {
            grid-template-columns: 1fr;
        }
        label{
            padding-left: 1rem;
            @include mobile{
                font-size: 1.8rem;
            }
        }
    
        .formbox1 {
            grid-column: span 1; // Ocupa 1 columna
            width: 100%;
        }
    
        .formbox2 {
            grid-column: span 3; // Ocupa 3 columnas
            width: 100%;
            display: grid;
        
            @include mobile {
                grid-column: span 1;
            }
            p{
                margin: .3rem 1.5rem 0px 1.5rem;
                font-size: .9rem;
                @include mobile{
                    font-size: 1.3rem;
                }
            }

            textarea{
                margin: 1rem 0 0;
                padding: 1rem;
                border: solid 2px $neo-white;
                border-radius: 1rem;
                color: $neo-white;
                background-color: transparent;
                font-family: 'SF Pro Display Light';
                font-size: 1.1rem;
                @include mobile{
                    font-size: 1.5rem;
                }
            }
        }
        .submitProposal{
            background-color: $neo-sky-blue;
            color: $neo-white;
            border-radius: 2rem;
            height: 3.5rem;
            width: 77%;
            font-family: "NeueHaasDisplay-Medium";
            font-size: 1.4rem;
            text-align: center;
            border: none;
            @include mobile{
                border-radius: 1rem;
                height: 3rem;
                font-size: 1.8rem;
            }
            &:hover {
                cursor: pointer;
                
            }
        }
    }
}

.successfully{
    color: $neo-primary;
    padding-left: 13rem;
    align-items: flex-start;
    justify-content: start;
    padding-top: 4rem;

    @include mobile{
        padding-top: 6rem;
        align-items: center;
        padding-left: 0;
    }

    mark{
        background-color: transparent;
        color: $neo-jobs;
    }
    &__title{
        inline-size: max-content;
        @include mobile{
            inline-size: min-content;
            font-size: 2.3rem;

        }
    }
    &__text{
        @include mobile{
            font-size: 1.5rem;
            text-wrap: balance;
        }
    }

    &__link{
        color: $neo-sky-blue;
        text-decoration: none;
        &:hover{
            text-decoration: underline;
        }
    }
}

#list {
    font-family: "SFProDisplay-Light";
    font-size: 1.4rem;
    color: $neo-primary;
    margin: 5px 0;
    border-radius: 2rem 0rem 1rem 2rem;
    background-color: $neo-white;
    padding: 1rem 2rem;
    height: 9rem;
    width: 20rem;
    overflow-y: scroll;
    list-style: none;
    text-decoration: none;
    @include mobile{
        font-size: 1.6rem;
    }
    &:hover {
        cursor: pointer;
    }
}
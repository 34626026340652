.navbar{
    border-radius: 1rem;
    display: inline;
    width: 37rem;
    justify-content: flex-end;
}


.adminNavbar{
    list-style: none;
    margin: 0 8rem;
    padding-right: 8rem;
    display: flex;
    justify-content: flex-end;
    gap: 3rem;
    height: 3rem;
    align-items: center;
    border-radius: 1rem;
    a{
        text-decoration: none;
    }
}
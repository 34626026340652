@import 'styles/_mixins.scss';

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  display: inline-block;

  height: 100vh;

  box-sizing: border-box;

  scroll-behavior: smooth;

  animation: scroll 10s ease-in-out 10s;

  @include mobile {
    font-size: 50%;
  }
  @include tablet-portrait {
    font-size: 70%;
  }
  @include tablet-landscape {
    font-size: 80%;
  }
  @include desktop {
    font-size: 100%;
  }
  @include big-desktop {
    font-size: 130%;
  }
}

#root {
  -moz-height: 100vh;
  height: 100vh;
  width: 100%;
  display: inline-block;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

img {
  display: block;
  max-width: 100%;
  height: auto;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

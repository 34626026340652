@import '../../../styles/_variables.scss';
@import '../../../styles/_mixins.scss';


.titleCareers {
    color: $neo-primary;
    font-size: 2rem;
    font-family: 'NeueHaasDisplay-Medium';
    font-weight: 900;
    margin-bottom: 2rem;

    @include mobile {
        font-size: 3rem;
    }

    mark {
        background-color: transparent;
        font-family: 'NeueHaasDisplay-Bold';
        color: $neo-green;
    }
}
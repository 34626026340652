@import 'styles/_variables.scss';
@import 'styles/_mixins.scss';

.header-neo {
    overflow: hidden;
    @include mobile {
        margin: 2rem;
        height: 6rem;
    }
}

.header {
    height: 6rem;
    width: 100%;
    padding: 0 5rem;
    display: grid;
    align-content: center;
    background-color: $neo-white;
    color: $neo-primary;
    font-size: 1.1rem;
    transition: all 0.5s linear;
    @include mobile {
        height: 6rem;
        padding: 0 1rem;
        justify-content: inherit;
    }

    &_brand {
        width: 20%;
        grid-area: brand;
        display: flex;
        align-items: center;
        justify-content: center;
        @include mobile {
            height: 5rem;
            width: 100%;
            justify-content: flex-start;
        }

        &--logo {
            transition: all 0.5s linear;
            fill: $neo-primary;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
            width: 13rem;
            cursor: pointer;
            @include mobile {
                width: 20rem;
                justify-content: flex-start;
            }
        }
    }

    &_language {
        height: 0;
        width: 0;
        display: block;

        &--button {
            top: 2.3rem;
            right: 4.5rem;
            height: 1.5rem;
            width: 1.5rem;
            display: flex;
            align-items: center;
            background-color: transparent;
            text-decoration: none;
            color: inherit;
            border: none;
            fill: $neo-primary;
            cursor: pointer;
            @include mobile {
                position: absolute;
                top: 4.2rem;
                width: 3.6rem;
                right: 13rem;
                height: 3.4rem;
            }
            @include big-desktop {
                right: 5.5rem;
            }
            img {
                height: 100%;
                width: 100%;
                transition: all 0.5s linear;

                &:hover {
                    scale: 105%;
                    transition: scale 0.5s ease;
                }
            }
        }
        
    }
    &_search--button {
        height: 0;
        width: 0;
        display: block;
        top: 2.4rem;
        right: 2.5rem;
        height: 1.5rem;
        width: 1.5rem;
        display: flex;
        align-items: center;
        background-color: transparent;
        text-decoration: none;
        color: inherit;
        border: none;
        fill: $neo-primary;
        cursor: pointer;
        @include mobile {
                position: absolute;
                top: 4.2rem;
                width: 3.6rem;
                right: 8.5rem;
                height: 3.4rem;
            }
            @include big-desktop {
                right: 3.7rem;
            }
    }

    
}

